
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const team_name = () => `Navn på selskapet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const headline1 = () => `For små og store bedrifter som trenger å sende ting enkelt og billig, uten alt styret.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const headline = () => `Superenkel frakt for Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started = () => `Kom igang`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const supereasy = () => `Superenkelt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started_fast = () => `Kom igang på 1 minutt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started_desc = () => `Med frakty kan du sende pakker på 1-2-3. Bare koble opp med 										Fiken, opprett en Profrakt avtale igjennom vårt dashboard og 										du er i gang.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_frakty = () => `Pris for Frakty`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_free_desc = () => `Grunnleggende tjeneste, perfekt for små bedrifter eller 											enkeltpersoner som sender i blant og har et lavt 											fraktvolum.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const month = () => `mnd`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send_as_much_as_you_want = () => `Send så mye du vil`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cheap_freight = () => `Billig frakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_plus_desc = () => `For bedrifter med større fraktbehov og egne avtaler. Gratis de første 20 sendingene.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cheaper_freight = () => `Billigere frakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const some_automation = () => `Noe automatisering (kommer)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_enterprise_desc = () => `Premium-tjeneste for bedrifter med høyt fraktvolum som 											trenger avanserte løsninger og dedikert support.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enterprice = () => `Bedrift`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const supercheap_freight = () => `Superbillig frakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const prio_support = () => `Prioritert support`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const automation = () => `Automatisering (kommer)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const packages_subtitle = () => `Ta gjerne kontakt dersom du lurer på noe`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_for_delivery = () => `Pris for levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const discounted_prices = () => `Rabatterte priser med en gang`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_for_delivery_desc = () => `Med Frakty er du allerede på en stordrifts avtale og du får 									rabatterte priser med en gang. Vi har forhandlet frem gode 									avtaler med Posten, Bring og Postnord.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const disocounted_table_header = () => `Ferdigforhandlet rabatter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frakty_free = () => `Frakty Gratis`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frakty_plus = () => `Frakty plus`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Frakty_enterpise = () => `Frakty bedrift`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_mailbox = () => `Pakke i postkassen`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_pickup = () => `Pakke til hentested`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_home = () => `Pakke levert hjem`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_business = () => `Pakke til bedrift`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const freight_to_business = () => `Stykkgods til bedrift`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fiken_easy_desc = () => `Opprett leveringer basert på dine fakturaer og salg i 											Fiken.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profrakt_label_easy = () => `Print fraktetiketter direkte på egen skriver eller 											automatisk via Profrakt Skyprint.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const do_you_need_to_send_something = () => `Trenger du å sende noe?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const you_can_use_it_for_free = () => `Du kan bruke Frakty gratis og du får bedre priser på frakten 									din.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_of_sale = () => `Salgsvilkår`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy = () => `Personvern`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send_with = () => `Send med`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send_from = () => `Send fra`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Change_sender = () => `Endre avsender?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sender_name_optional = () => `Avsendernavn (valgfritt)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Address = () => `Adresse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Postalcode = () => `Postnummer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const City = () => `By`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Floor_apartment_number_optional = () => `Etasje, leilighetsnummer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Enter_floor_and_apartment_number_ = () => `Skriv inn etasje og leilighetsnummer...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send_to = () => `Send til`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Name = () => `Navn`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Country = () => `Land`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const optional = () => `valgfri`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Email = () => `E-post`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Mobile = () => `Mobil`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Contact_person = () => `Kontaktperson`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Packages = () => `Pakker`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Amount = () => `Antall`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Description = () => `Beskrivelse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Describe_contents_of_package_ = () => `Beskriv innholdet i pakken...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Weightkg = () => `Vekt (kg)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Weight_OR_volume_is_required = () => `Vekt ELLER volum kreves`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Heightcm = () => `Høyde (cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Widthcm = () => `Bredde (cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Lengthcm = () => `Lengde (cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Volumedm3 = () => `Volum (dm3)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Add_package = () => `Legg til pakke`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const To_see_transports_register_required_recipient_information_and_add_weight_or_dimensions_to_at_least_one_package_ = () => `For å se transporter, registrer nødvendig mottakerinformasjon og legg til vekt eller dimensjoner i minst én pakke.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point = () => `Opphentingspunkt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_transportation_products_you_want_an_estimation_price_when_creating_deliveries = () => `Velg transportprodukter du ønsker en estimert pris når du oppretter leveranser.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point_not_needed_for_this_transportation_ = () => `Hentested er ikke nødvendig for denne transporten.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_pickup_points_found = () => `Ingen hentepunkter funnet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point_is_required_for_this_transport_ = () => `Hentested er nødvendig for denne transporten.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Update_pickup_points = () => `Oppdater hentepunkter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_transportation_to_see_services_ = () => `Velg transport for å se tjenester.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_services_available_for_this_transportation_ = () => `Ingen tjenester tilgjengelig for denne transporten.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Messages = () => `Meldinger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_to_Receiver = () => `Melding til mottaker`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_to_carrier = () => `Melding til transportør`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Senders_reference = () => `Avsenderens referanse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Receivers_reference = () => `Mottakers referanse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print = () => `Skrive ut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print_label_on_create = () => `Skriv ut etikett ved opprettelse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_printer = () => `Velg skriver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Standard_Profrakt_printer = () => `Standard Profrakt-skriver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Standard = () => `Standard`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print_to_PDF = () => `Skriv ut til PDF`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Spesific_printer = () => `Spesifikk skriver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Coming_later = () => `Kommer senere`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Delivery_created = () => `Levering opprettet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Download_Label = () => `Last ned etikett`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Track_delivery = () => `Spor levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Go_to_delivery = () => `Gå til levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_delivery = () => `Opprett levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login = () => `Log inn`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const _or_login_with = () => `..eller login med`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Login_with_Fiken = () => `Login med Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Signup = () => `Opprett konto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const _or = () => `.. eller`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Signup_with_Fiken = () => `Opprett konto med Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Frakty = () => `Frakty`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Dashboard = () => `Dashbord`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliveries = () => `Leveringer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Settings = () => `Innstillinger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Logout = () => `Logg ut`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sign_inn = () => `Log inn`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Norwegian = () => `Norsk`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const English = () => `Engelsk`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Toggle_navigation_menu = () => `Bytt navigasjonsmeny`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost_of_deliveries_past_month = () => `Estimert kostnad for leveranser siste måned`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost_of_deliveries_past_month_desc = () => `Vis hvor mye frakt du har hatt de siste dagene`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliveries_this_month_by_product = () => `Levering denne måneden etter produkt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Go_to_settings = () => `Gå til Innstillinger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const From = () => `Fra:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken_invoices = () => `Fiken fakturaer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken_sales = () => `Fiken salg`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const coming = () => `kommer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Gross = () => `Brutto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Track_Delivery = () => `Spor levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ID = () => `ID`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Transport = () => `Delivery type`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost = () => `Estimert kostnad`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Links = () => `Linker`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_tracking = () => `Ingen sporing`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Tracking = () => `Sporing`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_reference = () => `Ingen referanse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_results = () => `Ingen resultater`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken = () => `Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Profrakt = () => `Profrakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Billing = () => `Fakturering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_Subscription = () => `Velg Abonnement`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Edit = () => `Redigere`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Manage_subscription = () => `Administrer abonnement`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Subscription = () => `Abonnement`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Invoice_number = () => `Fakturanummer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Customer = () => `Kunde`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Created = () => `Opprettet`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Actions = () => `Handlinger`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliver = () => `Levere`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliver_again = () => `Lever igjen`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Contact_support = () => `Kontakt support`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Refrence = () => `Referanse`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Remove_integration = () => `Fjern integrasjon`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Configure = () => `Konfigurer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Configure_agreements = () => `Konfigurer avtaler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_Pluss_and_Enterprise = () => `kun Pluss og Enterprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_integration = () => `Ingen integrasjon`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_account = () => `Opprett konto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Register_company = () => `Registrer bedrift`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_a_new_Profrakt_account_Plus_and_Enterprise_subscriptions_can_register_an_API_key_if_you_allready_have_an_Profrakt_account_ = () => `Opprett et nytt Profrakt-konto.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Ingrated_with = () => `Integrert med`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_new_Profrakt_account = () => `Opprett ny Profrakt-konto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Or_connect_with_API_key = () => `Eller koble til med API-nøkkel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Invalid_phone_number = () => `Ikke gyldig mobilnummer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const One_uppercase_character = () => `One uppercase character`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const One_lowercase_character = () => `One lowercase character`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const At_least_4_characters = () => `At least 4 characters`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const First_name = () => `First name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Last_name = () => `Last name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Password = () => `Passord`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Phone_number = () => `Phone number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_Profrakt_Account = () => `Lag en Profrakt konto`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fill_demo_data = () => `Fyll med demo data`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const All_rights_reserved = () => `Alle rettigheter
				reservert`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const team_name_desc = () => `Navnet på selskapet / gruppen / avdeling etc`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sales = () => `Salg`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_name_on_customer = () => `Inget navn på kunde`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Open_in_Fiken = () => `Åpne i Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Save = () => `Lagre`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Freight_Alternatives = () => `Frakt alternativer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Company_name = () => `Selskaps navn`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Org_number = () => `Org nummer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Getting_freight_options = () => `Henter frakt alterantiver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Expected_delivery = () => `Forventet levering`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_pickuppoint = () => `Velg hentested`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Services = () => `Tjenester`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send = () => `Send`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Created_shipments = () => `Opprettet leveringer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Earlier_deliveries = () => `Tidligere sendt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Freight = () => `Frakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_Fiken_Company = () => `Velg Fiken selskap`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Select_Fiken_Company = () => `Velg Fiken selskap`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const App = () => `App`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Use_Profrakt_API_key = () => `Bruk Profrakt API nøkkel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Test_enviroment = () => `Test miljø`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_language = () => `Velg språk`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Register_with_Fiken = () => `Registrer deg med Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Confirm_Password = () => `Bekreft passord`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AmountCurrency = () => `Beløp`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Label = () => `Etikett`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Profrakt_Explainer = () => `Profrakt er en tjeneste som gir deg stordriftsavtale på frakt med			flere transportører. Du får tilgang til gode priser fra blant annet			Bring, PostNord og Helthjem. Betaling for frakt går via faktura til			Profrakt med 14 dagers betalingsutsettelse.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profrakt_create_account_explainer = () => `Du kan bruke Frakty gratis dersom du oppretter Profrakt
					avtalen gjennom oss.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profrakt_use_apikey_explainer = () => `Dersom du ønsker å bruke egen Profrakt avtale må du betale
					for Frakt integrasjoen etter 20 sendinger. Gratis frem til
					da så du får prøvd det.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profrakt_test_enviroment = () => `Dersom Fiken-selskapet er et testselskap, vil vi koble deg til
			Profrakt sitt testmiljø. Dette kan evt. overstyres her.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const freight_agreeements = () => `frakt avtaler`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const freight_products = () => `frakt produkter`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Or = () => `Eller`

