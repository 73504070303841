
/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/


	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const team_name = () => `Team name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const headline1 = () => `For small and large businesses that need to send things simply and cheaply, without all the fuss.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const headline = () => `Supereasy freight for Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started = () => `Get started`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const supereasy = () => `Supereasy`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started_fast = () => `Get started in 1 minute`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const get_started_desc = () => `With Frakty, you can send packages in a snap. Just connect with
										Fiken, create a Profrakt agreement through our dashboard and
										you're up and running.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_frakty = () => `Price Frakty`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_free_desc = () => `Basic service, perfect for small businesses or
											individuals who occasionally send and have a low
											shipping volume.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const month = () => `month`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send_as_much_as_you_want = () => `Send as much as you want`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cheap_freight = () => `Cheap freight`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_plus_desc = () => `For businesses with moderate shipping needs who want better
											prices and some support.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const cheaper_freight = () => `Cheaper freight
											`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const some_automation = () => `Some automation (coming)
											`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_enterprise_desc = () => `Premium service for businesses with high shipping volume that
											need advanced solutions and dedicated support.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const enterprice = () => `Enterprice`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const supercheap_freight = () => `Supercheap freight
											`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const prio_support = () => `Priority support
											`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const automation = () => `Automation (coming)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const packages_subtitle = () => `7-day free trial for paid subscriptions. Can be
								cancelled at any time.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_for_delivery = () => `Price for delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const discounted_prices = () => `Discounted prices immediately`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const price_for_delivery_desc = () => `With Frakty, you are already on a large-scale agreement and you get
									discounted prices immediately. We have negotiated good
									deals with Posten, Bring and Postnord.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const disocounted_table_header = () => `Pre-negotiated discounts`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frakty_free = () => `Frakty free`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const frakty_plus = () => `Frakty plus`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Frakty_enterpise = () => `Frakty Enterprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_mailbox = () => `Package in the mailbox`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_pickup = () => `Package for pickup location`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_home = () => `Package delivered home`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const package_business = () => `Package for Business`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const freight_to_business = () => `Freight to business`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const fiken_easy_desc = () => `Create deliveries based on your invoices and sales in Fiken.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const profrakt_label_easy = () => `Print shipping labels directly on your own printer or
											automatically via Profrakt Skyprint.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const do_you_need_to_send_something = () => `Do you need to send something?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const you_can_use_it_for_free = () => `You can use Frakty for free and get better prices on your freight.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const terms_of_sale = () => `Terms of Sale`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const privacy = () => `Privacy`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const send_with = () => `Send with`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send_from = () => `Send from`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Change_sender = () => `Change sender?`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sender_name_optional = () => `Sender name (optional)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Address = () => `Address`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Postalcode = () => `Postalcode`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const City = () => `City`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Floor_apartment_number_optional = () => `Floor, apartment number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Enter_floor_and_apartment_number_ = () => `Enter floor and apartment number...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send_to = () => `Send to`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Name = () => `Name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Country = () => `Country`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const optional = () => `optional`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Email = () => `Email`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Mobile = () => `Mobile`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Contact_person = () => `Contact person`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Packages = () => `Packages`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Amount = () => `Amount`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Description = () => `Description`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Describe_contents_of_package_ = () => `Describe contents of package...`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Weightkg = () => `Weight(kg)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Weight_OR_volume_is_required = () => `Weight OR volume is required`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Heightcm = () => `Height(cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Widthcm = () => `Width(cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Lengthcm = () => `	Length(cm)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Volumedm3 = () => `Volume(dm3)`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Add_package = () => `Add package`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const To_see_transports_register_required_recipient_information_and_add_weight_or_dimensions_to_at_least_one_package_ = () => `To see transports, register required recipient information,
									and add weight or dimensions to at least one package.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point = () => `Pickup point`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_transportation_products_you_want_an_estimation_price_when_creating_deliveries = () => `Choose transportation products you want an estimation price when creating deliveries.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point_not_needed_for_this_transportation_ = () => `Pickup point not needed for this transportation.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_pickup_points_found = () => `No pickup points found`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Pickup_point_is_required_for_this_transport_ = () => `Pickup point is required for this transport.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Update_pickup_points = () => `Update pickup points`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_transportation_to_see_services_ = () => `Choose transportation to see services.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_services_available_for_this_transportation_ = () => `No services available for this transportation.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Messages = () => `Messages`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_to_Receiver = () => `Message to Receiver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Message_to_carrier = () => `Message to carrier`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Senders_reference = () => `Sender´s reference`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Receivers_reference = () => `Receiver´s reference`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print = () => `Print`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print_label_on_create = () => `Print label on create`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_printer = () => `Choose printer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Standard_Profrakt_printer = () => `Standard Profrakt printer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Standard = () => `Standard`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Print_to_PDF = () => `Print to PDF`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Spesific_printer = () => `Spesific printer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Coming_later = () => `Coming later`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Delivery_created = () => `Delivery created`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Download_Label = () => `Download Label`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Track_delivery = () => `Track delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Go_to_delivery = () => `Go to delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_delivery = () => `Create delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const login = () => `Login`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const _or_login_with = () => `..or login with`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Login_with_Fiken = () => `Login with Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Signup = () => `Signup`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const _or = () => `..or`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Signup_with_Fiken = () => `Signup with Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Frakty = () => `Frakty`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Dashboard = () => `Dashboard`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliveries = () => `Deliveries`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Settings = () => `Settings`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Logout = () => `Logout`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sign_inn = () => `Sign inn`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Norwegian = () => `Norwegian`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const English = () => `English`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Toggle_navigation_menu = () => `Toggle navigation menu`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost_of_deliveries_past_month = () => `Estimated cost of deliveries past month`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost_of_deliveries_past_month_desc = () => `Show how much freight cost you have had the past days`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliveries_this_month_by_product = () => `Deliveries this month by product`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Go_to_settings = () => `Go to settings`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const From = () => `From:`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken_invoices = () => `Fiken invoices`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken_sales = () => `Fiken sales`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const coming = () => `coming`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Gross = () => `Gross`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Track_Delivery = () => `Track Delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const ID = () => `ID`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Transport = () => `Transport`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Estimated_cost = () => `Estimated cost`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Links = () => `Links`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_tracking = () => `No tracking`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Tracking = () => `Tracking`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_reference = () => `No reference`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_results = () => `No results`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fiken = () => `Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Profrakt = () => `Profrakt`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Billing = () => `Billing`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_Subscription = () => `Choose Subscription`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Edit = () => `Edit`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Manage_subscription = () => `Manage subscription`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Subscription = () => `Subscription`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Invoice_number = () => `Invoice number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Customer = () => `Customer`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Created = () => `Crated`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Actions = () => `Actions`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliver = () => `Deliver`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Deliver_again = () => `Deliver again`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Contact_support = () => `Contact support`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Refrence = () => `Refrence`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Remove_integration = () => `Remove integration`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Configure = () => `Configure`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Configure_agreements = () => `Configure agreements`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const only_Pluss_and_Enterprise = () => `only Pluss and Enterprise`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_integration = () => `No integration`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_account = () => `Create account`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Register_company = () => `Register company`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_a_new_Profrakt_account_Plus_and_Enterprise_subscriptions_can_register_an_API_key_if_you_allready_have_an_Profrakt_account_ = () => `Create a new Profrakt accoun.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Ingrated_with = () => `Ingrated with`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_new_Profrakt_account = () => `Create new Profrakt account`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Or_connect_with_API_key = () => `Or connect with API key`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Invalid_phone_number = () => `Invalid phone number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const One_uppercase_character = () => `One uppercase character`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const One_lowercase_character = () => `One lowercase character`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const At_least_4_characters = () => `At least 4 characters`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const First_name = () => `First name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Last_name = () => `Last name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Password = () => `Password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Phone_number = () => `Telephone number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Create_Profrakt_Account = () => `Create Prefreight Account`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Fill_demo_data = () => `Fill demo data`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const All_rights_reserved = () => `All rights reserved`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const team_name_desc = () => `This is the name of your team / company / organization.`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Sales = () => `Sales`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const No_name_on_customer = () => `Missing customer name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Open_in_Fiken = () => `Open in Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Save = () => `Save`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Freight_Alternatives = () => `Freight alternatives`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Company_name = () => `Company name`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Org_number = () => `Org number`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Getting_freight_options = () => `Getting freight options`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Expected_delivery = () => `Expected delivery`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_pickuppoint = () => `Choose pickuppoint`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Services = () => `Services`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Send = () => `Send`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Created_shipments = () => `Created shipments`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Earlier_deliveries = () => `Eearlier deliveries`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Freight = () => `Freight`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_Fiken_Company = () => `Select Fiken company`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Select_Fiken_Company = () => `Select Fiken Company`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const App = () => `Dæsjbord`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Use_Profrakt_API_key = () => `Bruk Profrakt api nøkkel`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Test_enviroment = () => `Test enviroment`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Choose_language = () => `Choose language`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Register_with_Fiken = () => `Register with Fiken`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const Confirm_Password = () => `Confirm password`




	
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const AmountCurrency = () => `Total`



export { Label } from "./no.js"

export { Profrakt_Explainer } from "./no.js"

export { profrakt_create_account_explainer } from "./no.js"

export { profrakt_use_apikey_explainer } from "./no.js"

export { profrakt_test_enviroment } from "./no.js"

export { freight_agreeements } from "./no.js"

export { freight_products } from "./no.js"

export { Or } from "./no.js"